import vue from 'vue';
// @ts-ignore
import CompleteComponent from './index.vue';

// 返回一个 扩展实例构造器
const CompleteConstructor = vue.extend(CompleteComponent);

let instance: any;
let seed = 1;

// 定义弹出组件的函数
function showComponent() {
  // 实例化一个 login.vue
  if (instance && instance.dialogVisible) {
    hideComponent();
  }
  const id = `$complete_info_${seed++}`;
  instance = new CompleteConstructor({
    data() {
      return {
        dialogVisible: true, // 是否显示组件
      };
    },
  });
  // @ts-ignore
  instance.id = id;
  // 把 实例化的 login.vue 添加到 body 里
  instance.$mount();
  document.body.appendChild(instance.$el);
}

function hideComponent() {
  if (instance) {
    instance.dialogVisible = false;
    document.body.removeChild(instance.$el);
  }
}

// 注册为全局组件的函数
function registryComplete() {
  // 将组件注册到 vue 的 原型链里去,
  // 这样就可以在所有 vue 的实例里面使用 this.$login()
  vue.prototype.$completeInfo = {
    show: showComponent,
    hide: hideComponent,
  };
}

export default registryComplete;
