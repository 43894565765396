// 1.禁用右键菜单
// document.addEventListener('contextmenu', function (e) {
//   const uri = new URLSearchParams(location.search);
//   const debug = uri.get('debug');
//   if (debug === null || debug === undefined) {
//     e.preventDefault(); // 阻止默认事件
//   }
// });

// document.addEventListener('selectstart', function (e) {
//   const uri = new URLSearchParams(location.search);
//   const debug = uri.get('debug');
//   if (debug === null || debug === undefined) {
//     e.preventDefault(); // 阻止默认事件
//   }
// });

document.addEventListener('keydown', function (e) {
  if (e.key === 'F12') {
    const uri = new URLSearchParams(location.search);
    const debug = uri.get('debug');
    if (debug === null || debug === undefined) {
      e.preventDefault(); // 如果按下键F12,阻止事件
    }
  }
});

function forceRefresh() {
  // 设置只强制刷行一次页面
  if (!location.href.includes('#reloaded')) {
    location.href = location.href + '#reloaded';
    window.location.reload(true);
  } else {
    alert('请手动刷新页面！');
  }
}

window.addEventListener('error', (e) => {
  const pattern = /Loading chunk (\d)+ failed/gi;
  const isChunkLoadFailed = pattern.test(e.message);
  if (isChunkLoadFailed) forceRefresh();
});
