/**
 * en_us
 * @author wwl
 * @created 2023/11/6
 * @modified 2023/11/6
 */
export default {
  // 通用模块
  common: {
    site_title: 'Exhibition net',
    more: 'More',
    packUp: 'Pack up',
    view: 'View',
    internation: 'Internation',
    china: 'China',
    industry: 'Industry',
    fair: 'Fair',
    concurrentFair: 'Concurrent fair',
  },

  innerChain: {
    hotArea: 'Hot area',
    hotIndustry: 'Hot industry',
    hotExhibition: 'Hot exhibition',
  },
  menu: {
    home: 'Home',
    domestic: 'China Fair',
    international: 'International Fair',
    exhibitionInfo: 'News',
    conventionCenter: 'Center',
    cooperate: 'Cooperate',
    visa: 'Visa',
    keywordPlaceholder1: 'Please enter a keyword',
    keywordPlaceholder2:
      'Search term length exceeds 50 word limit, please delete and try again',
    keywordPlaceholder3: 'Please enter Chinese characters or letters',
    keyword_error1: 'Only Chinese characters or letters can be searched',
    keyword_error2:
      'Search term length exceeds 50 word limit, please delete and try again',
    top: {
      ticket: 'My ticket',
      fair: 'My exhibition',
      industry: 'My industry',
      leaderboard: 'Leaderboard',
      sitemap: 'sitemap',
      signup: 'Login/Registration',
      order: 'My order',
      quit: 'logout',
    },
    // 首页模块
    homeModule: {},
    // 展会模块
    exhibitionModule: {},
    // 资讯模块
    infoModule: {},
    // 会展中心模块
    conventionModule: {},
    // 登录模块
    logonModule: {},
    // 个人中心模块
    personalModule: {},
  },
  // 页脚
  footer: {
    about: 'About us',
    help: 'Help',
    contact: 'Contact us',
    highpay: 'High salary recruitment',
    agreement: 'Service agreement',
    payment: 'Payment method',
    cooperation: 'Exhibition cooperation',
    ICP_recordNum: 'ICP:Zhe ICP 18015679',
    networkSecurity: 'Zhejiang public network Record 33011002013432',

    us_collaborate: 'Cooperate with us',
    customer: 'Customer service',
    booth_consult: 'Booth',
    viewer_consult: 'Audience',
    work_time: 'Monday to Sunday',
    work_time2: 'Monday to Friday',
    friendship_link: 'Friendly link',

    WeChatofficial: 'Wechat public account',
    WeChat_applet: 'Wechat mini program',
    desc: 'Please use mobile phone wechat scan code to follow',
    scan_follow: 'Scan the code to pay attention to the public number',
    scan_openApplet: 'Scan code to open the small program',
  },
  // 首页模块
  home: {
    top_container: {
      slogan: 'Global exhibition booth reservation, ticket reservation',
      inputPlaceholder: 'Country, city, industry, exhibition name',
    },
    hot_city: {
      title: 'Popular exhibition city：',
      city1: 'Shanghai',
      city2: 'Shenzhen',
      city3: 'Guangzhou',
      city4: 'Beijing',
      city5: 'Hong Kong',
      city6: 'Germany',
      city7: 'Japan',
      city8: 'America',
      city1_hover: 'Shanghai Exhibition',
      city2_hover: 'Shenzhen Exhibition',
      city3_hover: 'Guangzhou Exhibition',
      city4_hover: 'Beijing Exhibition',
      city5_hover: 'Hong Kong Exhibition',
    },
    kindTitle: 'Exhibition industry classification',
    hot_recommend: {
      hot_title: 'Recommend popular exhibitions',
      moreButton: 'More',
      international_hover: 'International exhibition',
      domestic_hover: 'Domestic exhibition',
      internationalTitle: 'International exhibition·Be about to start',
      domesticTitle: 'Domestic exhibition·Be about to start',
      distance: 'Distance',
      day: 'day',
      openness: 'Under way...',
      timeUpdate: 'Waiting update time...',
      newTrends_title: 'Latest news',
      exhibitionInfo_hover: 'Exhibition information',
      nuwInfo_title: 'Latest news',
      newExhibition_title: 'Latest exhibition',
      hot_country_title: 'Hot country',
      country_suffix3: 'exhibition',
      country_suffix2: 'exhibition',
      country_suffix1: 'exhibition',
      service_1: 'Global exhibition resources',
      service_2: 'Dedicated service consultant',
      service_3: 'One-stop service',
      service_4: 'Fast and accurate matching',
      service_5: 'It covers 99 countries',
    },
    hot_area: 'Hot area',
  },

  // 固定侧边栏
  fixed_sidebar: {
    phone: 'Phone',
    booth: 'Booth',
    viewer: 'Spectator',
    customer: 'Service',
    online_customer: 'Online customer',
    my: 'My',
    vote: 'Ticket',
    exhibition: 'Booth',
    cooperate: 'cooperation',
  },

  // 展会模块
  exhibition: {
    industryTitle: 'Industry category',
    concurrentFair: ' Industry exhibition',
    filters: {
      keyword: 'Keyword',
      selectedCondition: 'Selected condition',
      area: 'Area',
      region: 'Region',
      city: 'City',
      hotCity: 'Hot city',
      domestic: 'Domestic fair',
      domesticExh: 'Domestic fair(Hong Kong, Macao and Taiwan)',
      international: 'International fair',
      all: 'All',
      more: 'More',
      packUp: 'packUp',
    },
    scale: 'Scale',
    heatSort: 'Heat sort',
    timeSort: 'Time sort',
    recommendedExhibition: 'Recommended exhibition',
    industryNews: ' fair news',
    time: 'Time',
    latestNews: 'Latest news',
    timeUpdate: 'Waiting update time...',
    openness: 'Under way...',
    distance: 'Starts:',
    day: 'DAYS',
    year: ' year ', // 空格必须保留
    periods: ' periods',
    booth: 'Booth',
    ticket: 'Ticket',
    exhibitor: 'Exhibitor',
    hot: 'HOT',
    recommended: 'TOP PICK',
    winnow: 'SELECT',
  },
  // 展会详情页
  exhibitionDetail: {
    scale: 'Scale',
    profession: 'Profession',
    evaluate: 'Rating',
    spectator: 'Attend',
    market: 'Market',
    exhibitionAddress: 'Address',
    exhibitionHall: 'City',
    exhibitionArea: 'Exhibition area',
    openingHours: 'Show Hours',
    exhibitorsNumber: 'Exhibitors',
    exhibitorsNumberUnit: '',
    audienceSize: 'Visitors',
    audienceSizeUnit: '',
    cycle: 'Annual event',
    boothMap: 'Floorplan',
    getBoothMap: 'Get floorplan',
    subsidy: 'Subsidy',
    yes: 'Yes',
    samePeriodExhibition: 'Same period exhibition',
    viewTime: 'View time',
    viewMap: 'View map',
    exhibitionHallNumber: 'Exhibition hall number',
    travelReminder: 'Travel reminder',
    standardBooth: 'Standard booth',
    bareSpaceBooth: 'Bare space booth',
    projectManagerQuotation: 'Price',
    viewPrice: 'View price',
    lastDay: 'Entry before 14:00 is required on the last day of the exhibition',
    ticketDescription: 'Ticket description',
    exhibitionHighlights: 'Exhibition highlights',
    boothConsult: 'Booth',
    exhibitorPeriodical: 'Exhibitor Directory/E-Magazine',
    distance: 'Starts:',
    day: 'DAYS',
    exhibitionInProgress: 'Exhibition in progress',
    nexttermTimeRenew: 'Nextterm time renew',
    boothApply: 'Booth apply',
    ticketReservation: 'Get Ticket',
    freeForALimitedTime: 'Free for a limited time',
    tourItinerary: 'Tour itinerary',
    weChatShare: 'WeChat share',
    hotelReservation: 'Book hotel',
    exhibitionPicture: 'Exhibition photos',
    visaProcessing: 'Visa processing',
    poster: 'Poster',
    view: 'View',
    exhibitionIntroduction: 'Exhibition Profile',
    exhibitsRange: 'Range',
    relatedExhibition: 'Related exhibition',
    industry: 'Industry',
    organizer: 'Organizer',
    exhibitorNumber: 'Exhibitor number',
    exhibitionInvitationLetter: 'Exhibition Invitation letter',
    buyerData: 'Buyer data',
    exhibitionHall2: 'Venue',
    attend: 'People are interested',
    exhibitionReminder: 'Exhibition reminder',
    warmReminder: 'Warm reminder',
    warmReminderContent:
      'There is a certain risk of change in the exhibition time and location. Please refer to the electronic ticket voucher received. The exhibition will also provide phone and SMS notifications for users who book tickets to avoid travel losses',
    notificationReminder: 'Notification reminder',
    addReminder: 'Add reminder',
    viewReminder: 'View reminder',
    openNotification: 'Open notification',
    closeNotification: 'Close notification',
    changeNotice:
      'Check for changes in show hours and transportation information at your fingertips.',
    professionalAudience: 'Visitor Ticket Booking',
    electronic_journal: 'Exhibitor/E-Magazine',
    reserveButton: 'Reserve Now',
    ImmediateGet: 'Get it now',
    relatedHotspotRecommendation: 'Related hotspot recommendation',
    form1: {
      companyName: 'Please enter company name (required)',
      name: 'Please enter the contact name (required)',
      phone: 'Please enter contact number (required)',
      email: 'Please enter your email (optional)',
      remark: 'Please enter Remarks (optional)',
      submit: 'Submit',
      cancel: 'Cancel',
    },
  },
  // 展位申请页面
  boothApply: {
    nav: 'Booth reservation/Get floorplan',
    step1: 'Select service type',
    step2: 'Confirm company information',
    step3: 'Submit service order',
    step4: 'Customer follow-up docking',
    fairTime: 'Fair time',
    form: {
      input: 'Please enter (required)',
      scale: 'Please select the booth area (required)',
      submit: 'Submit',
    },
    tip: {
      title: 'Booth application instructions',
      n1: 'After the booth consultation is submitted, the staff will contact you one-on-one according to the reserved contact information within 1-2 working days',
      n1_1: 'After the booth application is submitted, the staff will contact you one-on-one within 1-2 working days according to the reserved contact information展位申请提交后，工作人员将在1 - 2个工作日内根据预留的联系方式与您进行一对一对接',
      n2: 'Submit the business license of the company',
      n3: 'Submit product picture and name',
      n4: 'Receive exhibition presentation documents and booth drawings',
      n5: 'Submit booth application form/Sign booth contract',
      n6: 'Pay the contract booth deposit',
      t1: 'Communication needs ',
      i1: 'Exhibitors reserve contact information, and the project leader will follow up and communicate the booth needs of exhibitors; ',
      t2: 'Submit for review ',
      i2: "After the exhibitors confirm the intention to participate with the project leader, submit the company name, product name and introduction, the company's official website, contact person, contact information, etc., waiting for the qualification review of the host; ",
      t3: 'Booth Offer ',
      i3: 'The project leader shall provide the exhibition invitation letter and the specific booth price to the exhibitors; ',
      t4: 'Booth map ',
      i4_1: '1. The exhibition has been mapped, and the optional booth map is provided for the exhibitors to choose;',
      i4_2: '2. If the exhibition is not mapped out, the booth deposit should be paid 2-3 months in advance to wait for the location allocation;',
      i4_3: '3. Tight exhibition, need to queue up at least 6 months in advance to wait for the location allocation; ',
      t5: 'Contract Signing ',
      i5: 'The exhibitor and the organizer sign the booth contract, stamped in two copies and effective, according to the contract payment; ',
      t6: 'Booth Related Services ',
      i6: 'After the booth is confirmed, the exhibitors will collect the information form, exhibitor card, exhibition equipment rental, car license and other materials according to the schedule; ',
      t7: 'Invoice ',
      i7: 'Issue the official invoice 7 working days after the end of the exhibition; ',
      t8: 'Comments and Comments ',
      i8: 'Collect the evaluation and feedback of exhibitors on the exhibition after the exhibition, and improve the service; ',
    },
  },
  // 客服
  customerService: {
    boothConsultation: 'Booth consultation',
    exhibitionTicketConsultation: 'Exhibition ticket consultation',
    boothSale: 'Booth Sales',
    telephone: 'Telephone',
    mobilePhone: 'Mobile phone',
    wechat: 'Wechat',
    onlineContact: 'Online contact',
    workTime: 'Work time',
  },
  // 展位价格
  booth: {
    boothPrice: 'Booth price',
    standard_booth: 'Standard booth',
    blank_booth: 'Blank booth',
    configuration: 'Configuration',
    price: 'Price',
    base: 'Base',
    viewPrice: 'View price',
    boothSampleGraph: 'Booth sample graph',
    tip: 'For reference only, the actual booth configuration shall prevail',
    viewFloorPrice: 'View floor price',
    configInfo1: {
      item1: 'Carpet',
      item2: 'A desk',
      item3: '2~3 chair',
      item4: 'A wastebasket',
      item5: 'Company lintel board',
      item6: '2~3 spotlight',
      item7: 'Two (three) face wall panels',
    },
    configInfo2: {
      item1: 'No facilities included',
      item2: 'Comply with pavilion restrictions',
      item3: 'Minimum size required',
    },
  },
  // 参展流程
  exhibitionProcess: {
    name: 'Exhibition process',
    item1: '1.Submit the business license of the company',
    item2: '2.Submit product picture and name',
    item3: '3.Receive exhibition presentation documents and booth drawings',
    item4: '4.Submit booth application form/Sign booth contract',
    item5: '5.Pay the contract booth deposit',
    item6: '6.Prepare for exhibition',
  },
  // 用户社区
  userCommunity: {
    userCommunity: 'Community',
  },

  // 资讯模块
  news: {
    information: '资讯',
    latestExhibition: '最新展会',
    latestNews: '最新资讯',
    notMultiple: '不支持多选',
  },

  // 会展中心模块
  pavilion: {
    name: '会展中心',
    filters: {
      region: '地区',
      all: '全部',
      china: '中国',
      nation: '国家',
      more: '更多',
      packUp: '收起',
    },
    total: '共',
    stripStadium: '条场馆',
    countryAndRegion: '国家地区',
    pavilionAddress: '展馆地址',
    pavilionArea: '展馆面积',
    squareMeter: '平方米',
    recommendedExhibition: '推荐展会',
  },

  // 登录模块
  login: {
    title: '全球展会展位预定、门票预订',
    wechatLoginOrRegister: '微信扫码登录或注册',
    phoneOrEmail: '手机/邮箱验证码登录',
    passwordLogin: '账号密码登录',
    accountHere: '账号登录在这里',
    scanCodeHere: '扫码登录在这里',
    forgotPassword: '忘记密码',
    registerNow: '立即注册',
    signup: '登录',
    register: '账号注册',
    bindAccount: '绑定账号',
    registerBtnText: '注 册',
    phonePlaceholder: '输入手机号或邮箱',
    phonePlaceholder2: '输入手机号',
    phoneError1: '请输入你的手机号或邮箱',
    phoneError2: '请输入正确的手机号或邮箱',
    phoneError3: '该手机号或邮箱未注册',
    phoneError4: '请输入你的手机号',
    phoneError5: '请输入正确的手机号',
    phoneError6: '该手机号已绑定',
    passwordPlaceholder: '输入登录密码',
    passwordError1: '请输入登录密码',
    newPasswordPlaceholder: '输入新登录密码',
    newPasswordError1: '请输入你的新密码',
    codePlaceholder: '输入验证码',
    codeError1: '请输入你的验证码',
    getVerificationCode: '获取验证码',
    getInSeconds: '秒后获取',
    resetPassword: '重置密码',
    parameterInvalid: '参数不合法',
    iHaveReadAndAgree: '我已阅读并同意',
    serviceAgreement: '第三方商品平台交易服务协议',
    serviceAgreementError: '请阅读并勾选服务协议',
    bindingLogin: '绑定登录',
    scanCodeLogin: '扫码登录',
    verificationCodeLogin: '验证码登录',
    passwordLoginText: '密码登录',
  },

  // 个人中心模块
  personal: {
    left: {
      fans: '粉丝',
      follow: '关注',
      personal_center: '个人中心',
      my_order: '我的订单',
      my_exhibition: '我的展会',
      my_vote: '我的门票',
      my_visa: '我的签证',
      my_hotel: '我的酒店',
      my_planeTicket: '我的机票',
      exhibitor_list: '展商名录',
      exhibitions_followed: '关注的展会',
      authentication: '企业认证',
      change_password: '修改密码',
    },
    right: {
      // 个人中心
      follow_industry: '关注的行业',
      add_button: '添加',
      recent: '近期展会',
      distance: '距离开展',
      day: '天',
      ln_progress: '开展中...',
      time_updating: '时间更新中......',
      modify_info: '修改个人资料',
      head_sculpture: '头像',
      prompt: '点击图片替换头像',
      user_name: '用户名',
      user_placeholder: '请输入用户名(必填)',
      gender: '性别',
      gender_placeholder: '请选择性别(必选)',
      male: '男',
      female: '女',
      corporate_name: '公司名称',
      corporate_plader: '请输入公司名称(必填)',
      main_products: '主营产品',
      main_placeholder: '请输入公司产品(必填)',
      company_website: '公司网址',
      company_plader: '请输入公司名称(选填)',
      duties: '职务',
      duties_plader: '请输入职务(必填)',
      email: '邮箱',
      email_plader: '请输入邮箱(选填)',
      confirm_button: '确认',
      modifyInfo_inputRule1: '请输入用户名.',
      modifyInfo_inputRule2: '请选择性别.',
      modifyInfo_inputRule3: '昵称不能是手机号、电话号、邮箱或包含特殊字符',
      modifyInfo_inputRule4: '请输入公司名称.',
      modifyInfo_inputRule5: '请输入公司产品',
      modifyInfo_inputRule6: '请输入职位.',
      // 我的展会
      applied_for: '我申请过的展会',
      participate: '参展公司',
      exhibit_content: '展品内容',
      booth_type: '展位类型',
      booth_area: '展位面积',
      processed: '已处理',
      pending: '待处理',
      raw_sace: '光地展位',
      standard_booth: '标准展位',
      advanced_booth: '进阶展位',
      // 我的门票
      my_vote: '我的门票',
      whole: '全部',
      tobepaid: '待支付',
      issue_tickets: '出票中',
      stop_tickets: '已出票',
      refunded: '已退款',
      // 我的签证
      my_visa: '我的签证',
      // 我的酒店
      my_hotel: '我的酒店',
      // 我的机票
      my_plane_ticket: '我的机票',
      closed: '已关闭',
      // 展商名录
      order_num: '订单编号',
      create_time: '创建时间',
      electronic_journal: 'Exhibitor',
      ear_of_publication: '会刊年份',
      journal_name: '会刊名称',
      notes1:
        '备注：展商名录/电子会刊将在1-2个工作日内以电子邮件形式发送至申请人邮箱，请注意查收(如未收到请查看垃圾邮箱或联系0571-88560061)',
      notes2: '在线展商名录仅支持浏览，不发邮件，不支持复制文字等内容',
      refer_to_button: '查阅会刊',
      state_button1: '开发票',
      state_button2: '开发票中',
      state_button3: '查看发票',
      order_status1: '未支付',
      order_status2: '已申请',
      order_status3: '已支付',
      order_status4: '已完成',
      // 关注的展会
      correct_follow: '已关注',
      deny_follow: '关注',
      follow_exhibition: '关注展会',
      industry_follow: '关注行业',
      launch_time: '还有',
      launch_time_day: '天',
      open_develop: '开展中',
      nextterm_time_renew: '下届时间更新中',
      view_details: '查看详情',
      other_user_box1: '其他一些推荐',
      other_user_box2: '的用户',
      recommend_exhibition: '推荐展会',
      ETag_status1: '普通',
      ETag_status2: '延期',
      ETag_status3: '变更',
      ETag_status4: '取消',
      // 企业认证
      account_login: '账号登录:',
      auth_corporate: '公司名称:',
      your_corporate: '您的公司名称',
      english_name: '英文名称',
      your_english_name: '您的公司英文名称',
      corporate_address: '公司地址:',
      select_address: '请选择地址',
      indetail_address: '详细地址:',
      where_address: '所在公司地址',
      corporate_introduce: '公司介绍:',
      introduce: '介绍',
      corporate_phone: '公司电话',
      registered_capital: '注册资金:',
      select_capital: '请选择企业注册资金',
      capital_option1: '100万以下',
      capital_option2: '100-500万',
      capital_option3: '500万-1000万',
      capital_option4: '1000万-3000万',
      capital_option5: '3000万以上',
      staff_num: '员工人数:',
      select_staff_num: '请选择员工人数',
      staff_num_option1: '10-30人',
      staff_num_option2: '30-50人',
      staff_num_option3: '50-100人',
      staff_num_option4: '100人以上',
      service_area: '服务区域:',
      delete: '删除',
      contacts: '联系人:',
      Enter_contacts: '请输入联系人',
      mobile_phone: '手机号:',
      Enter_phone: '请输入手机号',
      Enter_qq: '请输入qq',
      WeChat: '微信:',
      Enter_WeChat: '请输入微信',
      mailbox: '邮箱:',
      Enter_mailbox: '请输入邮箱',
      position: '职位:',
      Enter_position: '请选择职位',
      position_option1: '普通员工',
      position_option2: '经理',
      position_option3: '总经理',
      position_option4: '董事长',
      id_card: '身份证:',
      business_license: '营业执照:',
      qualifications: '公司资质:',
      corporate_photos: '企业照片:',
      enterprise_video: '企业视频:',
      select_video: '选择视频',
      video_size_limit: '视频大小不能大于 10 M',
      submit_authenti: '提交认证',
      // 修改密码
      login_account: '登录账号:',
      message_code: '短信验证码:',
      new_password: '新密码:',
      submit: '提交',
      obtain_code: '获取验证码',
      code_cannot_empty: '短信验证码不能为空',
      new_psd_cannot_empty: '新密码不能为空',
      password_tooweak: '密码强度不够',
      second_obtain: '秒后获取',
      param_illegal: '参数不合法',
    },
  },
  // title, description, keyword
  tdk: {
    home: {
      title: '聚展网-展会网_国际展会_展会门票_展会信息服务平台',
      keywords: '展会,博览会,展会门票,展会会刊,交易会,展会信息',
      description:
        '聚展网为您提供专业的展会信息服务,展会门票,展会会刊,展位预定,展会推广,展会行程,展会物流,展会搭建,展位设计,专业观众引流,展会信息触达全球一百多个国家',
    },
    ticket: 'Ticket',
    time: 'Time',
    magazine: 'Exhibitor Directory/E-Magazine',
    jufair: 'Juzhan',
    jufair2: 'Juzhan',
    fairTime: 'Time',
    address: 'Address',
    sponsor: 'Sponsor',
    // 展会详情页
    exhibitionDetail: {
      description:
        'Juzhan network provides exhibition ticket and booth booking services',
      keyword1: '',
      keyword2: ' time',
      keyword3: ' ticket',
      keyword4: ' floorplan',
      keyword5: ' price',
    },
  },
};
